import React from 'react';
import styled, { keyframes } from 'styled-components';

const pingPongBallXKeyframes = keyframes`
  from,
  to {
    transform: translate(40px, 80px);
  }
  50% {
    transform: translate(88px, 80px);
  }
`;

const pingPongBallYKeyframes = keyframes`
  from,
  50%,
  to {
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transform: translate(0, 0);
  }
  25%,
  75% {
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translate(0, -68px);
  }
`;

const pingPongPaddleXKeyframes = keyframes`
  from,
  to {
    transform: translate(36px, 92px) rotate(6deg);
  }
  50% {
    transform: translate(92px, 92px) rotate(-6deg);
  }
`;

const pingPongPaddleYKeyframes = keyframes`
  from,
  50%,
  to {
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transform: translate(0, 0);
  }
  25%,
  75% {
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translate(0, 28px);
  }
`;

const PingPongContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PingPongSVG = styled.svg`
  display: block;
  margin: auto;
  width: 8em;
  height: 8em;
`;

const PingPongBallX = styled.g`
  animation: ${pingPongBallXKeyframes} 1.5s linear infinite;
`;

const PingPongBallY = styled.circle`
  animation: ${pingPongBallYKeyframes} 1.5s linear infinite;
`;

const PingPongPaddleX = styled.g`
  animation: ${pingPongPaddleXKeyframes} 1.5s cubic-bezier(0.33, 1, 0.68, 1) infinite;
`;

const PingPongPaddleY = styled.rect`
  animation: ${pingPongPaddleYKeyframes} 1.5s linear infinite;
`;

const PingPongLoadingAnimation = React.memo(() => {
  return (
    <PingPongContainer>
      <PingPongSVG viewBox="0 0 128 128" width="128px" height="128px">
        <defs>
          <linearGradient id="ping-pong-grad" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="#000" />
            <stop offset="100%" stopColor="#fff" />
          </linearGradient>
          <mask id="ping-pong-mask">
            <rect x="0" y="0" width="128" height="128" fill="url(#ping-pong-grad)" />
          </mask>
        </defs>
        <g fill="#000">
          <PingPongBallX>
            <PingPongBallY r="10" />
          </PingPongBallX>
          <PingPongPaddleX>
            <PingPongPaddleY x="-30" y="-2" rx="1" ry="1" width="60" height="4" />
          </PingPongPaddleX>
        </g>
        <g fill="#fff" mask="url(#ping-pong-mask)">
          <PingPongBallX>
            <PingPongBallY r="10" />
          </PingPongBallX>
          <PingPongPaddleX>
            <PingPongPaddleY x="-30" y="-2" rx="1" ry="1" width="60" height="4" />
          </PingPongPaddleX>
        </g>
      </PingPongSVG>
    </PingPongContainer>
  );
});

export default PingPongLoadingAnimation;