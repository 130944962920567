import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

const ScrollProgressBar = ({ onComplete }) => {
  const progressBarRef = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  
  const animateProgress = () => {
    // Calcolo dinamico della percentuale di scroll
    const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
    setScrollProgress(scrollPercentage);

    // Applicazione di un'animazione avanzata alla barra di progresso
    gsap.to(progressBarRef.current, {
      width: `${scrollPercentage}%`,
      // backgroundColor: `hsl(${360 * scrollPercentage / 100}, 100%, 50%)`,
      ease: 'power3.out',
      onUpdate: () => {
        // Aggiornamento dinamico del gradiente per un effetto visivo più ricco
        // progressBarRef.current.style.background = `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(${scrollPercentage + 155}, 255, ${scrollPercentage + 100}, 1) 50%, rgba(0,212,255,1) 100%)`;
        progressBarRef.current.style.background = `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(${255 - scrollPercentage * 2.55}, ${255 - scrollPercentage * 2.55}, ${255 - scrollPercentage * 2.55}, 1) 100%)`;

      }
    });

    // Invocazione del callback al completamento
    if (scrollPercentage >= 100) onComplete?.();
  };

  useEffect(() => {
    const handleScroll = () => animateProgress();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollProgress]);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '6px', // Leggero aumento dell'altezza per maggiore visibilità
      zIndex: 9999,
      backgroundColor: 'transparent', // Sfondo trasparente per un aspetto più pulito
      boxShadow: '0 2px 4px rgba(0,0,0,0.3)' // Ombra leggera per profondità
    }}>
      <div ref={progressBarRef} style={{
        height: '100%',
        width: '0%',
        boxShadow: '0 0 8px rgba(255, 255, 255, 0.8)', // Luce soffusa per un effetto luminoso
      }} />
    </div>
  );
};

export default ScrollProgressBar;
