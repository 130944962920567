import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useParams, useNavigate, Navigate } from 'react-router-dom';
import { I18nextProvider, withTranslation, useTranslation } from 'react-i18next';
import i18n from './i18n';
import Cursore from './components/CursorComponent';
import CircularLoadingAnimation from './animazioni/loading';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import ScrollProgressBar from './animazioni/barra_caricamento_progressiva';
import '../src/components/AboutMePreview.css';
import ScrollToTop from '../src/animazioni/ScrollToTop';
import { Helmet } from 'react-helmet';
import WhatsAppButton from './animazioni/WhatsAppButton';
import { Element, scroller } from 'react-scroll';

const Home = React.lazy(() => import('./pages/Home'));
const Services = React.lazy(() => import('./pages/Services'));
const Websites = React.lazy(() => import('./pages/WebSites'));
const Descubre = React.lazy(() => import('./pages/Descubre'));
const Contatti = React.lazy(() => import('./pages/Contatti'));
const About = React.lazy(() => import('./pages/About'));
const Consulting = React.lazy(() => import('./pages/Consulting'));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div>{this.props.t('errore_caricamento_pagina')}</div>;
    }
    return this.props.children;
  }
}

const TranslatedErrorBoundary = withTranslation()(ErrorBoundary);

gsap.registerPlugin(MotionPathPlugin);

const MyNavLink = ({ to, children }) => {
  const { lang } = useParams();
  return <Link to={`/${lang}${to}`}>{children}</Link>;
};

const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    scroller.scrollTo('scroll-container', {
      duration: 3000,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="overflow-x-hidden">
          <Helmet>
            <link rel="alternate" hreflang="en" href="https://www.homeholidaybnb.com/" />
            <link rel="alternate" hreflang="it" href="https://www.homeholidaybnb.com/it/" />
            <link rel="alternate" hreflang="es" href="https://www.homeholidaybnb.com/es/" />
            <link rel="alternate" hreflang="de" href="https://www.homeholidaybnb.com/de/" />
            <link rel="alternate" hreflang="fr" href="https://www.homeholidaybnb.com/fr/" />
            <link rel="alternate" hreflang="ru" href="https://www.homeholidaybnb.com/ru/" />
            <link rel="alternate" hreflang="en" href="https://www.homeholidaybnb.com/services" />
            <link rel="alternate" hreflang="it" href="https://www.homeholidaybnb.com/it/services" />
            <link rel="alternate" hreflang="es" href="https://www.homeholidaybnb.com/es/services" />
            <link rel="alternate" hreflang="de" href="https://www.homeholidaybnb.com/de/services" />
            <link rel="alternate" hreflang="fr" href="https://www.homeholidaybnb.com/fr/services" />
            <link rel="alternate" hreflang="ru" href="https://www.homeholidaybnb.com/ru/services" />
            <link rel="alternate" hreflang="en" href="https://www.homeholidaybnb.com/consulting" />
            <link rel="alternate" hreflang="it" href="https://www.homeholidaybnb.com/it/consulting" />
            <link rel="alternate" hreflang="es" href="https://www.homeholidaybnb.com/es/consulting" />
            <link rel="alternate" hreflang="de" href="https://www.homeholidaybnb.com/de/consulting" />
            <link rel="alternate" hreflang="fr" href="https://www.homeholidaybnb.com/fr/consulting" />
            <link rel="alternate" hreflang="ru" href="https://www.homeholidaybnb.com/ru/consulting" />
            <link rel="alternate" hreflang="en" href="https://www.homeholidaybnb.com/websites" />
            <link rel="alternate" hreflang="it" href="https://www.homeholidaybnb.com/it/websites" />
            <link rel="alternate" hreflang="es" href="https://www.homeholidaybnb.com/es/websites" />
            <link rel="alternate" hreflang="de" href="https://www.homeholidaybnb.com/de/websites" />
            <link rel="alternate" hreflang="fr" href="https://www.homeholidaybnb.com/fr/websites" />
            <link rel="alternate" hreflang="ru" href="https://www.homeholidaybnb.com/ru/websites" />
            <link rel="alternate" hreflang="en" href="https://www.homeholidaybnb.com/about" />
            <link rel="alternate" hreflang="it" href="https://www.homeholidaybnb.com/it/about" />
            <link rel="alternate" hreflang="es" href="https://www.homeholidaybnb.com/es/about" />
            <link rel="alternate" hreflang="de" href="https://www.homeholidaybnb.com/de/about" />
            <link rel="alternate" hreflang="fr" href="https://www.homeholidaybnb.com/fr/about" />
            <link rel="alternate" hreflang="ru" href="https://www.homeholidaybnb.com/ru/about" />
            <link rel="alternate" hreflang="en" href="https://www.homeholidaybnb.com/contact" />
            <link rel="alternate" hreflang="it" href="https://www.homeholidaybnb.com/it/contact" />
            <link rel="alternate" hreflang="es" href="https://www.homeholidaybnb.com/es/contact" />
            <link rel="alternate" hreflang="de" href="https://www.homeholidaybnb.com/de/contact" />
            <link rel="alternate" hreflang="fr" href="https://www.homeholidaybnb.com/fr/contact" />
            <link rel="alternate" hreflang="ru" href="https://www.homeholidaybnb.com/ru/contact" />
          </Helmet>
          <ScrollProgressBar />
          <Cursore />
          <ScrollToTop />
          <Element name="scroll-container">
            <TranslatedErrorBoundary>
              <Suspense fallback={<div>{t('caricamento')}</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/websites" element={<Websites />} />
                  <Route path="/descubre" element={<Descubre />} />
                  <Route path="/contact" element={<Contatti />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/consulting" element={<Consulting />} />
                  <Route path="/:lang" element={<Home />} />
                  <Route path="/:lang/Services" element={<Services />} />
                  <Route path="/:lang/websites" element={<Websites />} />
                  <Route path="/:lang/descubre" element={<Descubre />} />
                  <Route path="/:lang/contact" element={<Contatti />} />
                  <Route path="/:lang/about" element={<About />} />
                  <Route path="/:lang/consulting" element={<Consulting />} />
                  <Route path="*" element={<Navigate to="/en" replace />} />
                </Routes>
              </Suspense>
            </TranslatedErrorBoundary>
          </Element>
          <WhatsAppButton />
        </div>
      </Router>
    </I18nextProvider>
  );
};

export default App;