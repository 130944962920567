import React, { useState, useEffect } from 'react';

const CursorFollower = () => {
  const [cursorPos, setCursorPos] = useState({ x: -100, y: -100, visible: false, active: false });
  const [isMobile, setIsMobile] = useState(false);

  const onMouseMove = (e) => {
    // Verifica se l'elemento sotto il cursore è cliccabile
    const isClickable = e.target.tagName === 'A' || e.target.tagName === 'BUTTON' || e.target.tagName === 'INPUT';
    setCursorPos({ x: e.clientX, y: e.clientY, visible: true, active: isClickable });
  };

  const onMouseLeave = () => {
    setCursorPos((prevState) => ({ ...prevState, visible: false }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    handleResize(); // Controlla la larghezza dello schermo all'avvio

    window.addEventListener('resize', handleResize); // Aggiorna la larghezza dello schermo quando la finestra viene ridimensionata

    if (!isMobile) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      window.removeEventListener('resize', handleResize);

      if (!isMobile) {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [isMobile]);

  if (isMobile) {
    return null; // Non mostrare il cursore su schermi mobili
  }

  return (
    <div
      className={`cursor-follower ${cursorPos.visible ? 'visible' : ''} ${cursorPos.active ? 'active' : ''}`}
      style={{
        left: `${cursorPos.x}px`,
        top: `${cursorPos.y}px`,
      }}
    ></div>
  );
};

export default CursorFollower;