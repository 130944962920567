import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const WhatsAppButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsVisible(false);

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <a
      href="https://wa.me/+34604923114"
      target="_blank"
      alt="whatsapp"
      rel="noopener noreferrer"
      className={`fixed bottom-2 right-0 z-50 transform transition-all duration-700 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
      }`}
    >
      <div className="relative inline-flex items-center justify-center w-11 h-11 md:w-16 md:h-16 text-white bg-green-600 rounded-full shadow-lg transform hover:scale-110 transition duration-300">
        <FaWhatsapp className="text-4xl" />
        <div className="absolute top-0 right-0 bottom-0 left-0 rounded-full bg-green-600 opacity-30 animate-ping"></div>
      </div>
    </a>
  );
};

export default WhatsAppButton;